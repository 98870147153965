function recaptchaCallback() {
    $('#f_recaptcha').valid();
}

var w;
var h;

function calcWindowWidthAndHeight() {
    w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}


$(document).ready(function () {


    // Check if password is strong
    $('#register_form #reg_password, #register_form #reg_conf_password, #password_form #change_password, #password_form #change_conf_password, #pass_recover #forget_password, #pass_recover #forget_conf_password').keyup(function () {
        var input = $(this);
        var form = $(this).closest('form');
        console.log(form);
        input.prev('.pass_error').empty();

        var pass = $(this).val();
        var valid = false;
        var strength = 0;

        if (pass.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
            valid = true;
            strength += 1;
        } else {
            valid = false;
        }

        if (pass.match(/([a-zA-Z])/) && pass.match(/([0-9])/)) {
            valid = true;
            strength += 1;
        } else {
            valid = false;
        }

        if (pass.match(/([!,%,&,@,#,$,^,*,?,_,~,-])/)) {
            valid = true;
            strength += 1;
        } else {
            valid = false;
        }

        if (pass.length > 6) {
            valid = true;
            strength += 1;
        } else {
            valid = false;
        }

        if (valid == true && strength == 4) {
            form.find('input[type=submit]').attr("disabled", false);
            input.prev('.pass_error').empty();
        } else {
            form.find('input[type=submit]').attr("disabled", true);
            input.prev('.pass_error').append("<p>Veuillez renseigner un mot de passe contenant au moins 6 caractères avec une minuscule, une majuscule, un nombre et un caractère spécial </p>");
        }
    });

    // Check if confirm password is equal to password
    $('#register_form #reg_conf_password').keyup(function () {
        $('.confpass_error').empty();

        var conf_pass = $(this).val();
        var pass = $('#register_form #reg_password').val();

        if (conf_pass == pass) {
            $("#reg_submit").attr("disabled", false);
            $('.confpass_error').empty();
        } else {
            $("#reg_submit").attr("disabled", true);
            $('.confpass_error').append("<p>Veuillez renseigner des mots de passe identique</p>");

        }
    });

    $('#password_form #change_conf_password').keyup(function () {
        $('.confpass_error').empty();

        var conf_pass = $(this).val();
        var pass = $('#password_form #change_password').val();

        if (conf_pass == pass) {
            $("#pass_submit").attr("disabled", false);
            $('.confpass_error').empty();
        } else {
            $("#pass_submit").attr("disabled", true);
            $('.confpass_error').append("<p>Veuillez renseigner des mots de passe identique</p>");

        }
    });

    $('#pass_recover #forget_conf_password').keyup(function () {
        $('.confpass_error').empty();

        var conf_pass = $(this).val();
        var pass = $('#pass_recover #forget_password').val();

        if (conf_pass == pass) {
            $("#recover_submit").attr("disabled", false);
            $('.confpass_error').empty();
        } else {
            $("#recover_submit").attr("disabled", true);
            $('.confpass_error').append("<p>Veuillez renseigner des mots de passe identique</p>");

        }
    });
    $.validator.addMethod("validateRecaptcha", function (value, element) {
        if (grecaptcha.getResponse() == '') {
            return false;
        } else {
            return true;
        }
    }, "Vous devez valider le reCAPTCHA");

    setTimeout(function () {
        if ($('.form-user form').length) {
            $('#contactRgpd').rules('add', {
                validateCgv: true
            });
            $('#f_recaptcha').rules('add', {
                validateRecaptcha: true
            });
        }
    }, 100);

    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide."
    });
    $.validator.addMethod("validateCgv", function (value, element) {
        return $('#contactRgpd').is(':checked');
    }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $('.form-user form').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.form-group');
                error.addClass("checkbox");
                parentElm.append(error);
            } else if (element.attr('type') === undefined) {
                var parentElm = element.closest('.form-group');
                error.addClass("select");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });



    $('.wysiwyg').find('iframe').wrap('<div class="embed-container"></div>');



    // Univers header
    $('.universActive').click(function () {
        $('.universToggle').toggleClass('active');
        $('.universHeader').toggleClass('active');

    })

    $('.closeCrossUnivers').click(function(){
        $(this).parent().removeClass('active');
        $(this).parent().prev().removeClass('active');
    })


    // Submenu

    $('.btnSubmenu').click(function () {
        if($(this).hasClass('active')){
            $('.btnSubmenu').removeClass('active');
        } else {
            $('.btnSubmenu').removeClass('active');
            $(this).addClass('active');
        }

    })

    $('.closeCross').click(function(e){
        e.stopPropagation();
        $('.btnSubmenu').removeClass('active');
        $(this).parent().removeClass('active');
        $(this).parent().prev().removeClass('active');
    });




    // OWL-carousel Block

    // var IDnumber = 1;
    // var NEXTPREVnumber = 1;

    // $('.owl-carousel-ressources').each(function () {

    //     var newID = 'owl-carousel-ressources' + IDnumber;

    //     $(this).attr('id', newID);
    //     IDnumber++;

    //     var owl = $('#' + newID);


    //     // owl.owlCarousel({
    //     //     loop: true,
    //     //     nav: false,
    //     //     items: 1,
    //     //     dots: true
    //     // });


    //     owl.trigger('refresh.owl.carousel');

    //     $(this).next().addClass('owlNextRessources' + NEXTPREVnumber);
    //     $(this).next().next().addClass('owlPrevRessources' + NEXTPREVnumber);

    //     // Go to the next item
    //     $('.owlNextRessources' + NEXTPREVnumber).click(function () {
    //         sync1.trigger('next.owl.carousel');
    //     });

    //     // Go to the previous item
    //     $('.owlPrevRessources' + NEXTPREVnumber).click(function () {
    //         // With optional speed parameter
    //         // Parameters has to be in square bracket '[]'
    //         sync1.trigger('prev.owl.carousel', [300]);

    //     });

    //     NEXTPREVnumber++;

    // });

    var sync1 = $("#sync1");
    var sync2 = $("#sync2");
    var slidesPerPage = 10;
    var syncedSecondary = true;

    sync1.owlCarousel({
        items : 1,
        nav: false,
        autoplay: false,
        dots: false,
        loop: true,
        smartSpeed: 2000,
        responsiveClass: true,
        responsive: {
            0 : {
                dots : true,
            },
            768 : {
                dots: false,
            }
        }
    }).on('changed.owl.carousel', syncPosition);

    sync2
        .on('initialized.owl.carousel', function () {
        sync2.find(".owl-item").eq(0).addClass("current");
        })
        .owlCarousel({
        items : slidesPerPage,
        dots: false,
        nav: false,
        margin: 5,
        freeDrag: true,
        slideBy: slidesPerPage,
        smartSpeed: 2000,
        responsiveRefreshRate : 100
    }).on('changed.owl.carousel', syncPosition2);

    function syncPosition(el) {
        //if you set loop to false, you have to restore this next line
        //var current = el.item.index;

        //if you disable loop you have to comment this block
        var count = el.item.count-1;
        var current = Math.round(el.item.index - (el.item.count/2) - .5);



        //end block

        sync2
        .find(".owl-item")
        .removeClass("current")
        .eq(current)
        .addClass("current");
        var onscreen = sync2.find('.owl-item.active').length - 1;
        var start = sync2.find('.owl-item.active').first().index();
        var end = sync2.find('.owl-item.active').last().index();


    }

    function syncPosition2(el) {
        if(syncedSecondary) {
        var number = el.item.index;
        sync1.data('owl.carousel').to(number, 100, true);
        }
    }

    sync2.on("click", ".owl-item", function(e){
        e.preventDefault();
        var number = $(this).index();
        sync1.data('owl.carousel').to(number, 300, true);
    });

        $(".prevBtnRessources").click(function() {
            $('.owl-carousel-ressources').trigger('prev.owl.carousel');
        });

        $(".nextBtnRessources").click(function() {
            $('.owl-carousel-ressources').trigger('next.owl.carousel');
        });

    // OWL-carousel univers

    var IDnumber = 1;
    var NEXTPREVnumber = 1;

    $('.owl-carousel-univers').each(function () {

        var newID = 'owl-carousel-univers' + IDnumber;

        $(this).attr('id', newID);
        IDnumber++;

        var owl = $('#' + newID);


        owl.owlCarousel({
            loop: true,
            nav: false,
            margin: 5,
            items: 1,
            dots: true
        });

        owl.trigger('refresh.owl.carousel');

        $(this).next().addClass('owlNextUnivers' + NEXTPREVnumber)
        $(this).next().next().addClass('owlPrevUnivers' + NEXTPREVnumber)

        // Go to the next item
        $('.owlNextUnivers' + NEXTPREVnumber).click(function () {
            owl.trigger('next.owl.carousel');
            $(this).parent().find('.owlPrevUnivers').addClass('active');

        })

        // Go to the previous item
        $('.owlPrevUnivers' + NEXTPREVnumber).click(function () {
            // With optional speed parameter
            // Parameters has to be in square bracket '[]'
            owl.trigger('prev.owl.carousel', [300]);

        });

        NEXTPREVnumber++;

    });

    // OWL-carousel Cross

    var IDnumber = 1;
    var NEXTPREVnumber = 1;

    $('.owl-carousel-cross').each(function () {

        var newID = 'owl-carousel-cross' + IDnumber;

        $(this).attr('id', newID);
        IDnumber++;

        var owl = $('#' + newID);


        owl.owlCarousel({
            loop: false,
            nav: false,
            items: 6,
            dots: false,
            margin: 5,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 2
                },
                767: {
                    items: 3
                },
                991: {
                    items: 4
                },
                1199: {
                    items: 5
                },
                1400: {
                    items: 6
                }
            }
        });

        owl.trigger('refresh.owl.carousel');

        $(this).next().addClass('owlNextCross' + NEXTPREVnumber)
        $(this).next().next().addClass('owlPrevCross' + NEXTPREVnumber)

        // Go to the next item
        $('.owlNextCross' + NEXTPREVnumber).click(function () {
            owl.trigger('next.owl.carousel');
            $(this).parent().find('.owlPrevCross').addClass('active');
        })

        // Go to the previous item
        $('.owlPrevCross' + NEXTPREVnumber).click(function () {
            // With optional speed parameter
            // Parameters has to be in square bracket '[]'
            owl.trigger('prev.owl.carousel', [300]);

        });

        NEXTPREVnumber++;

    });

    function toggleLoop() {
        $('.owl-carousel-cross').each(function () {

            var itemsCount;
            var loopStatus = false;

            itemsCount = $(this).find('.owl-item:not(.cloned)').length;

            calcWindowWidthAndHeight();

            if (itemsCount > 6) {
                loopStatus = true;

            } else if (itemsCount == 6) {

                loopStatus = w < 1400 ? true : false;

            } else if (itemsCount == 5) {

                loopStatus = w < 1199 ? true : false;

            } else if (itemsCount == 4) {

                loopStatus = w < 991 ? true : false;

            } else if (itemsCount == 3) {

                loopStatus = w < 767 ? true : false;

            } else {

                loopStatus = w >= 767 ? false : false;

            }

            loopStatusCurrent = $(this).data('owl.carousel').options.loop;
            if (loopStatusCurrent != loopStatus) {
                if (loopStatus) {
                    $(this).data('owl.carousel').options.loop = true;
                    $(this).trigger('refresh.owl.carousel');
                    // L'OPTION LOOP EST TRUE
                    // Do something...
                    $(this).parent().find('.owlPrevCross').addClass('show');
                    $(this).parent().find('.owlNextCross').addClass('show');

                } else if (!loopStatus) {
                    $(this).data('owl.carousel').options.loop = false;
                    $(this).trigger('refresh.owl.carousel');
                    // L'OPTION LOOP EST FALSE
                    // Do something...
                    $(this).parent().find('.owlPrevCross').removeClass('show');
                    $(this).parent().find('.owlNextCross').removeClass('show');
                }
            }


        });
    }

    toggleLoop();
    $(window).resize(toggleLoop);



    //owl.data('owl.carousel').options.loop = false;
    //owl.trigger( 'refresh.owl.carousel' );

    // SUMMARY
    if ($('.contentSingle').length > 0) {
        i = 1;
        $('.contentSingle').find('.summaryItem').each(function () {
            $(this).find('span').before('<span class="number">' + i + '</span>');
            i++;
        });
    }

    if ($('ol').length > 0) {

        $('ol').each(function () {
            i = 1;
            $(this).find('li').each(function () {
                $(this).prepend('<span class="number">' + i + '</span>');
                i++;
            })
        });
    }

    // SUMMARY
    if ($('.step').length > 0) {
        console.log('&');
        i = 1;
        $('.contentSingle').find('.step').each(function () {
            $(this).prepend('<span class="stepNumberCont"><span class="stepNumber">' + i + '</span></span>');
            i++;
            console.log('e')
        });
    }

    // MENU

    $('.toggleMenu').click(function () {
        $(this).toggleClass('active');
        $('.menuMobile').toggleClass('active');
        $('html').toggleClass('oh');
    })

    $('.titleRessources').click(function () {
        $(this).parent().find('.ownSubmenu').addClass('openSub');
    })

    $('.titleSubmenu').click(function () {
        $(this).parent().removeClass('openSub');
    })


    // CRÉATION DE COMPTE

    $('.titleCategories').click(function(){
        var h = 60;



        if($(this).parent().hasClass('active')){
            $('.categPlaces').removeClass('active');
            $('.categPlaces').css('height', h);


        } else {
            $('.categPlaces').removeClass('active');
            $('.categPlaces').css('height', h);

            $(this).parent().toggleClass('active');
            h = 0;
            $(this).parent().children().each(function(){
                h = h + $(this).outerHeight(true);
            });
        }

        $(this).parent().css('height', h);

    });



    // CHECKBOX CHOICES CARNET
    $('.choices').on('click', '.myChoices', function () {
        // Je récupère uniquement le myCHoices sur lequel j'ai cliqué
        var myChoices = $(this);

        // Je récupère l'attribu data-idcenter du myChoices sur lequel j'ai cliqué
        var idcenter = myChoices.attr('data-idcenter');

        // Pour chaque element ayant la classe .checkedCenterID
        $('.checkedCenterID').each(function () {
            // Si la valeur de .checkedCenterID est égale à l'attribu de .myChoices
            if ($(this).val() == idcenter) {
                // alors on unchecked .checkedCenterID
                $(this).prop("checked", false);
                // et on enlève le .myChoices
                myChoices.remove();
            }
        });
    });

    function displayNbOfCheck() {
        $('.titleCategories').each(function(){
            nbOfCheck = $(this).attr('nbOfCheck');

            if(nbOfCheck == 0){
                $(this).removeClass('nbOfCheck');
            } else {
                $(this).addClass('nbOfCheck');
            }
        });
    }


    $('.categPlaces').each(function(){

        var nbOfCheck = 0;

        $(this).find('.checkedCenterID').each(function(){
            if($(this).is(':checked')){
                nbOfCheck++;
            };
        });

        $(this).find('.titleCategories').attr('nbOfCheck', nbOfCheck);
    });

    displayNbOfCheck();


    $('.checkedCenterID').click(function () {

        // Je récupère uniquement le checkedCenterID sur lequel j'ai cliqué
        var myInput = $(this);

        // Je récupère sa valeur
        var inputValue = myInput.val();

        titleCategories = $(this).parent().parent().parent().find('.titleCategories');
        nbOfCheck = titleCategories.attr('nbOfCheck');


        // Si le .checkedCenterID passe en checked
        if (myInput.is(':checked')) {

            // Je récupère le contenu du lable associé au .checkedCenterID
            var inputLabel = myInput.next('label').text();

            // Je créé .myChoices et je lui ajoute son attribu ainsi que son contenu
            $('.choices').append('<p class="myChoices" data-idcenter="' + inputValue + '">' + inputLabel + '</p>')

            // Je rajoute un au nombre de choix dans cette categ
            nbOfCheck++;

        } else { // À l'inverse si le .checkedCenterID passe en unchecked

            // Pour chaque element .myChoices
            $('.myChoices').each(function () {

                // Si son attribu est égal à la valeur de .checkedCenterID
                if ($(this).attr('data-idcenter') == inputValue) {

                    // Alors j'enlève l'element .myChoices
                    $(this).remove();
                }
            })

            nbOfCheck--;
        }


        titleCategories.attr('nbOfCheck', nbOfCheck);
        displayNbOfCheck()


    })

    $('.addRessources').click(function () {
        var realisation = $(this);
        var id = realisation.find('input[name="rea_id"]').val();
        var method = realisation.find('input[name="action"]').val();

        $.ajax({
            'url': ajaxurl,
            'type': 'GET',
            'async': false,
            'data': '&id=' + id + '&method=' + method + '&action=add_ressources',
            success: function (response) {
                var res = JSON.parse(response);
                if (method == 'add') {
                    realisation.find('input[name="action"]').val('delete');
                    realisation.find('p').text('Supprimer de mon carnet de bord');
                    $('.coeur').removeClass('coeurWhite');
                    $('.coeur').addClass('coeurBleu');
                }
                if (method == 'delete') {
                    realisation.find('input[name="action"]').val('add');
                    realisation.find('p').text('Ajouter à mon carnet de bord');
                    $('.coeur').removeClass('coeurBleu');
                    $('.coeur').addClass('coeurWhite');
                }
            }

        });
    });

    $('.dashboard_remove_res').click(function() {
        var element = $(this);
        var id = element.find('input[name="dashboard_rea_id"]').val();
        console.log(id);
        $.ajax({
            'url': ajaxurl,
            'type': 'GET',
            'async': false,
            'data': '&id=' + id + '&method=delete&action=add_ressources',
            success: function (response) {
                var res = JSON.parse(response);
                $('.oneRessources').each(function() {
                    if ($(this).attr('data-res-id') == id) {
                        $(this).remove();
                    }
                });
            }

        });

    })

    $('.print').click(function() {
        window.print();
    })

    $('.univers-choices').change(function(){
        $('#form_univers').submit();
    });

    $('.univers-change').change(function(){
        $('#form_change_univers').submit();
    });

    ///////////////////////////////////////////////////////////////////
    //////////                    KONAMI                    //////////
    /////////////////////////////////////////////////////////////////
    // var k = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65],
    // n = 0;
    // $(document).keydown(function (e) {
    //     if (e.keyCode === k[n++]) {
    //         if (n === k.length) {
    //             var src = templateUrl + '/images/paon-merignac.png';
    //             $('body').append('<img id="paon">');
    //             $('#paon').attr('src',src);
    //             $('body').append('<audio id="paon_audio" style="opactity:0;" src="' + templateUrl + '/mp3/paon.mp3"></audio>');
    //
    //             $("#paon_audio")[0].play();
    //
    //             setTimeout(function(){
    //                 $("#paon").addClass('paon-move');
    //             }, 5000);
    //             return false;
    //         }
    //     }
    //     else {
    //         n = 0;
    //     }
    // });

    // FAQ ACCORDEON

    $('.eachFaq').each(function() {
        var heightTitleFaq = $(this).find('.titleFaq').outerHeight();

        $(this).css('height', heightTitleFaq);
    });

    $('.titleFaq').click(function() {

        var heightContentFaq = $(this).next().outerHeight(true) + 30;
        var heightTitleFaq = $(this).outerHeight();


        if($(this).parent().hasClass('active')) {
            $(this).parent().removeClass('active');
            $(this).parent().css({
                'height': heightTitleFaq,
                'overflow': 'hidden',
            });
        } else {
            $(this).parent().addClass('active');
            $(this).parent().css({
                'height': heightContentFaq,
            });
        }
    });





});
